import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { IControlChatMessageBody, IFeed } from "@itorum/models";

export interface RoomStateState {
  chatMessages: IControlChatMessageBody[];
  feeds: IFeed[];
  isWidescreen: boolean;
  isShowDraw: boolean;
  isNowShared: boolean;
  image: File | string;
  file: File | Blob;
  reqScreenshotUrl: string;
  isSideModalOpen: boolean;
  isAllPublishersMuted: boolean;
  doTerminateWorker: boolean;
  isGuardActive: boolean;
}

/**
 * Эта функция создает начальное состояние комнаты.
 * @returns Функция createInitialState возвращает объект типа RoomStateState со следующими свойствами и начальными
 * значениями:
 */
export function createInitialState(): RoomStateState {
  return {
    feeds: [],
    isWidescreen: false,
    isShowDraw: false,
    isNowShared: false,
    image: null,
    file: null,
    chatMessages: [],
    reqScreenshotUrl: null,
    isSideModalOpen: false,
    isAllPublishersMuted: false,
    doTerminateWorker: true,
    isGuardActive: true
  };
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "roomState" })
export class RoomStateStore extends Store<RoomStateState> {

  constructor() {
    super(createInitialState());
  }

}
