/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DefectDefectIdBody } from '../model/defectDefectIdBody';
import { DefectRegistryBody } from '../model/defectRegistryBody';
import { InlineResponse20043 } from '../model/inlineResponse20043';
import { InlineResponse20044 } from '../model/inlineResponse20044';
import { InlineResponse20045 } from '../model/inlineResponse20045';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StepDefectsService {

  protected basePath = 'https://dev.itorummr.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Get step defect statuses
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepDefectCategoriesGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20044>;
  public apiStepDefectCategoriesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20044>>;
  public apiStepDefectCategoriesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20044>>;
  public apiStepDefectCategoriesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse20044>('get',`${this.basePath}/api/step/defect/categories`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get step defect by id
   *
   * @param defectId Defect id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepDefectDefectIdGet(defectId: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20043>;
  public apiStepDefectDefectIdGet(defectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20043>>;
  public apiStepDefectDefectIdGet(defectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20043>>;
  public apiStepDefectDefectIdGet(defectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (defectId === null || defectId === undefined) {
      throw new Error('Required parameter defectId was null or undefined when calling apiStepDefectDefectIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse20043>('get',`${this.basePath}/api/step/defect/${encodeURIComponent(String(defectId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update step defect
   *
   * @param defectId Defect id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepDefectDefectIdPut(defectId: number, body?: DefectDefectIdBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20043>;
  public apiStepDefectDefectIdPut(defectId: number, body?: DefectDefectIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20043>>;
  public apiStepDefectDefectIdPut(defectId: number, body?: DefectDefectIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20043>>;
  public apiStepDefectDefectIdPut(defectId: number, body?: DefectDefectIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (defectId === null || defectId === undefined) {
      throw new Error('Required parameter defectId was null or undefined when calling apiStepDefectDefectIdPut.');
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20043>('put',`${this.basePath}/api/step/defect/${encodeURIComponent(String(defectId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Defect registry
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepDefectRegistryPost(body?: DefectRegistryBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20045>;
  public apiStepDefectRegistryPost(body?: DefectRegistryBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20045>>;
  public apiStepDefectRegistryPost(body?: DefectRegistryBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20045>>;
  public apiStepDefectRegistryPost(body?: DefectRegistryBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20045>('post',`${this.basePath}/api/step/defect/registry`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get step defect statuses
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepDefectStatusesGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20044>;
  public apiStepDefectStatusesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20044>>;
  public apiStepDefectStatusesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20044>>;
  public apiStepDefectStatusesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse20044>('get',`${this.basePath}/api/step/defect/statuses`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
